import React from "react"
import SwiperCore, { Pagination, Navigation } from "swiper/core"
import { Swiper } from "swiper/react"
import "swiper/swiper.min.css"
require("swiper/components/pagination/pagination.min.css")

SwiperCore.use([Pagination])

export default function Slider({ children, sliderSettings }) {
  const pagination = {
    clickable: true,
    renderBullet: function () {
      return '<span class="custom-swiper-pagination swiper-pagination-bullet"></span>'
    },
  }
  if (sliderSettings.navigation) {
    SwiperCore.use([Navigation])
    require("swiper/components/navigation/navigation.min.css")
  }

  return (
    <Swiper
      navigation={sliderSettings.navigation ? sliderSettings.navigation : false}
      pagination={pagination}
      slidesPerView={sliderSettings.slidesPerView}
      centerInsufficientSlides={true}
      spaceBetween={sliderSettings.spaceBetween}
      breakpoints={sliderSettings.breakpoints}
      watchOverflow={true}
      style={sliderSettings?.sliderStyles}
    >
      {children}
    </Swiper>
  )
}
